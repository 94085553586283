p {
  font-size: 16px;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 5px;
}

.table-points {
  color: #fff;
  border-bottom: 0px;
}

.table-standings {
  table-layout: fixed;
  width: 100%;
  margin-bottom: 0px;
  border-spacing: 0px 0px;
  border-collapse: separate;
  font-weight: 400;
  overflow: hidden;
  font-size: 0.875rem;
}

.table-standings thead tr th {
  color: #555;
  font-size: 0.9375rem;
  font-weight: 500;
}

.table-ranking {
  table-layout: fixed;
  margin-bottom: 0px;
  border-spacing: 0px 0px;
  border-collapse: separate;
  font-weight: 400;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  overflow: hidden;
  font-size: 0.875rem;
}

.table-ranking-container {
  width: 100%;
  overflow: auto;
}

.table-standings .table-standings th,
.table-standings td,
.table-standings-dia .table-standings-dia th,
.table-standings-dia td {
  border: 0px;
}

.table-standings-team {
  width: 39%;
}
.table-standings-number {
  width: 5%;
}

.table-standings-team-1 {
  width: 44%;
}

.table-standings-team-2 {
  width: 40.5%;
}

.table-standings-team-3 {
  width: 37%;
}

.table-standings-team-4 {
  width: 33.5%;
}

.table-standings-team-5 {
  width: 30%;
}

.table-standings-team-6 {
  width: 26.5%;
}

.table-standings-team-7 {
  width: 23%;
}

.table-standings-team-8,
.table-standings-team-9,
.table-standings-team-10 {
  width: 19.5%;
}

.table-standings-dia {
  width: 100%;
  margin-bottom: 0px;
  border-spacing: 2px 0px;
  border-collapse: separate;
}

.table thead tr th {
  color: #3c4858;
  font-size: 0.9375rem;
  font-weight: 500;
}

.table-position {
  min-width: 5px;
}

.table-schema {
  margin-bottom: 0px;
  border-spacing: 2px 0px;
  border-collapse: separate;
}

.table-schema .table-schema th,
.table-schema td {
  border: 0px;
}

.table-score {
  line-height: 1.5;
}

.field-referee {
  border-bottom: 1px solid #ddd;
  padding: 5px 5px 0px 5px;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 20px;
}

.field-referee-present {
  border-bottom: 1px solid #ddd;
  padding: 5px 5px 0px 5px;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 0px;
  font-weight: 50;
}

.btn.btn-white,
.btn.btn-white:focus,
.btn.btn-white:hover {
  color: #000;
}

.btn.btn-white:focus,
.btn.btn-white:hover {
  -webkit-box-shadow: 0 14px 26px -12px rgba(249, 242, 255, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(249, 242, 255, 0.2);
  box-shadow: 0 14px 26px -12px rgba(249, 242, 255, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(249, 242, 255, 0.2);
}

.btn-clean {
  box-shadow: none;
  -webkit-box-shadow: none;
  background: none;
  color: inherit;
  font-weight: 500;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(130%);
  }
  100% {
    transform: translateY(0);
  }
}

.alert-tournify {
  background: rgb(53 53 53 / 97%);
  color: #fff;
  font-weight: 400;
  align-items: baseline;
  left: 0px;
  position: fixed;
  z-index: 999;
  bottom: 0px;
  transform: translateY(130%);
  animation: 0.5s ease-out 2s 1 normal forwards running slideInFromBottom;
  padding: 15px;
  border-radius: 5px;
  margin: 15px;
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
}

.alert-tournify .btn {
  margin-left: auto;
}

.alert-tournify-text {
  margin-right: 10px;
}

.signup-label {
  color: #555;
}

.navbar-brand {
  font-size: 1.5rem;
}

.nav-phases {
  display: block;
  width: 200px;
  margin: 0px auto;
}

.nav-tabs {
  display: flex;
  flex-wrap: nowrap;
  text-align: center;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 15px 3.5vw 15px 3.5vw;
}

.nav-icon,
.nav-tabs .nav-item .material-icons {
  margin-right: 5px;
}

.btn-group-lg .btn {
  padding: 0.9rem 1.75rem;
}

.serif-font {
  font-family: "Roboto", "Times New Roman", serif;
}

a.tournify-footer {
  color: #3c4858;
}

a.tournify-footer:hover,
a.tournify-footer:focus {
  color: #2278cf;
}

.footer .copyright {
  padding-top: 15px;
  padding-right: 0.9375rem;
}

.projects-1 {
  padding: 20px 0px 40px 0px;
}

.main.main-raised.blog-post-main {
  margin: 0px 30px 0px;
}

.LoggedIn {
  position: absolute;
  z-index: 10;
  right: 10px;
  top: 10px;
}

.LoggedInBubble {
  display: flex;
  padding: 3px 5px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  font-size: 0.8rem;
  line-height: 1.1;
  font-weight: 500;
  height: 26px;
  cursor: default;
  justify-content: flex-end;
  width: fit-content;
  margin-left: auto;
}

.LoggedInBubbleText {
  padding: 4px 8px 4px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80vw;
}

.LoggedInBubbleAvatar {
  position: relative;
  bottom: 2px;
  margin-left: 1px;
  margin-right: 5px;
}

.LoggedInBubbleLogo {
  border-radius: 50%;
  background: #3c4858;
}

.LoggedInMenu {
  z-index: 11;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  font-size: 0.8rem;
  line-height: 1.1;
  font-weight: 500;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  margin-top: 3px;
}

.LoggedInMenuItem {
  opacity: block;
  width: 100%;
  padding: 10px;
  font-weight: 400;
  color: #3c4858;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.LoggedInMenuItemText {
  margin-right: 10px;
}

.LoggedInMenuItem:hover {
  background: rgb(233, 233, 233);
  color: #3c4858;
}

@media screen and (max-width: 767px) {
  .main.main-raised.blog-post-main {
    margin: 0px 10px 0px;
  }
}

.footer-white {
  margin-top: -50px;
  padding-top: 60px;
}

.tournament-title {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #fff;
}

.img-raised {
  max-height: 120px;
  max-width: 80vw;
  margin-bottom: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.img-raised.jpeg {
  -webkit-box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  background: #fff;
}

.sponsor-img {
  max-width: 60%;
}

.rotating-image {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120px;
  margin: -60px 0 0 -60px;
  -webkit-animation: spin 8s linear infinite;
  -moz-animation: spin 8s linear infinite;
  animation: spin 8s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes animate {
  45%,
  50% {
    transform: translate(-50%, -50%) scale(1.4);
  }
}

.navbar .navbar-brand {
  font-size: 1.5rem;
  font-family: Roboto;
  font-weight: 500;
}

.logo-icon {
  max-width: 30px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.nav-pills.nav-pills-tournify {
  padding: 0px;
}

.vertical-align {
  display: flex;
  align-items: center;
}

.icon.icon-tournify {
  color: #2278cf;
}

h1,
.h1 {
  font-size: 2rem;
}

.card {
  margin-top: 0px;
}

.Poule {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: 20px;
  border-radius: 5px;
  overflow: hidden;
}

.card .card-header-tournify-blue,
.card.bg-tournify-blue,
.card.card-rotate.bg-tournify-blue .front,
.card.card-rotate.bg-tournify-blue .back {
  background: linear-gradient(60deg, #2278cf, #4d92d8);
}

.card-collapse .card-header {
  border: none;
}

.card-collapse .card-header a {
  display: flex;
}

.card-collapse .card-header-title {
  width: calc(100% - 20px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #555;
  font-weight: 400;
}

.card-collapse .card-header a i {
  top: 0;
  margin-right: 5px;
}

.card-collapse.collapse-schedule .card-body {
  padding: 10px;
}

.MyMatchesContainer {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 20px;
}

.card-blog {
  margin-top: 0px;
}

.card-pricing {
  text-align: center;
  margin-top: 20px;
}

.card-collapse .card-body {
  padding: 0px 0px 5px;
}

.front-background .card-title {
  margin-top: 30px;
}

[class*="contactus-"] {
  padding: 30px 0;
}

.signup-page .card-signup {
  margin-bottom: 20px;
  padding: 20px;
}

.header-standard {
  background-image: linear-gradient(
    to right top,
    #2f6a84,
    #307795,
    #3184a6,
    #3091b7,
    #2f9fc9,
    #24a4cb,
    #15aacd,
    #00afce,
    #00adbd,
    #00aaaa,
    #00a694,
    #29a17e
  );
}

.blog-post .section-text p {
  font-size: 1rem;
}

.container.header-top {
  margin-top: -95vh;
  margin-bottom: 30px;
  color: #fff;
  z-index: 10;
}

.page-header {
  z-index: -1;
}

.header-tournament-inner::before {
  background: none;
}

.header-filter::before {
  background: rgba(0, 0, 0, 0.2);
}

.features-3 .phone-container {
  max-width: none;
}

.MatchGroup {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  justify-items: center;
}

[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

.upload-label {
  border: 1px solid #3c4858;
  border-radius: 0.2rem;
  color: #3c4858;
  cursor: pointer;
  display: inline-block;
  outline: none;
  padding: 10px;
  position: relative;
  vertical-align: middle;
  margin-bottom: 0px;
  margin-right: 5px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.75rem;
}

.upload-label:hover {
  background-color: rgb(245, 245, 245);
}

.upload-logo-label:hover {
  background-color: rgb(245, 245, 245);
}

.EmptyState {
  text-align: center;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.EmptyState svg {
  color: black;
  margin-top: 40px;
  margin-bottom: 5px;
  max-width: 80%;
  width: 228px;
  height: 136px;
}
.EmptyTitle {
  font-weight: 700;
}

.TeamDetailsCard {
  display: flex;
  border-radius: 5px 5px 0 0;
  padding: 15px 10px 15px 15px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  align-items: center;
}

.OpenInAppCard {
  position: absolute;
  right: 0;
  margin-right: 3.5vw;
  border-radius: 5px;
  padding: 10px 15px 10px 15px;
  align-items: center;
  margin-bottom: 10px;
  font-size: 0.875rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  color: white;
}

.OpenInAppCard .btn.btn-tournify {
  background-color: white;
  color: #555;
}

.OpenInAppCard .btn.btn-tournify:hover,
.OpenInAppCard .btn-tournify:not(:disabled):not(.disabled):active {
  background-color: rgba(255, 255, 255, 0.8);
  color: #555;
}

.OpenInAppButton {
  position: relative;
}

.OpenInAppButton:after {
  content: "";
  position: absolute;

  margin-left: -14.5px;
  margin-top: 19px;
}

.NotificationBadge:before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: rgb(249, 29, 29);
  margin-left: 7px;
  margin-top: 6px;
  z-index: 2;
}

.RefereeDetailsCard {
  display: flex;
  border-radius: 5px;
  padding: 15px 10px 15px 15px;
  margin-bottom: 20px;
  align-items: center;
}

.TeamDetailsTab,
.TeamDetailsTabActive {
  flex-grow: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  padding: 0 5px;
  line-height: 30px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.TeamDetailsTabs .WithoutHeader,
.TeamDetailsTabs.WithoutHeader {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  margin-bottom: 20px;
}

.TeamDetailsTabs .WithHeader,
.TeamDetailsTabs.WithHeader {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  margin-bottom: 20px;
}

.TeamDetailsTabs .WithHeader > .TeamDetailsTab,
.TeamDetailsTabs.WithHeader > .TeamDetailsTab,
.TeamDetailsTabs .WithHeader > .TeamDetailsTabActive,
.TeamDetailsTabs.WithHeader > .TeamDetailsTabActive {
  border-radius: 0 0 5px 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  flex-grow: 1;
}

.TournifySearch {
  display: flex;
  align-items: flex-start;
  border-radius: 5px;
  padding: 6.5px 5px 6.5px 15px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: 20px;
}

.TournifySearchInput {
  height: unset;
  font-size: 0.875rem;
  color: #555;
  font-weight: 400;
  padding: 6px 5px 6px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-image: none;
  font-size: 16px;
}

.TeamDetailsTabs {
  flex-wrap: nowrap;
}

.TeamDetailsLogo {
  min-width: 40px;
  max-width: 40px;
  padding-left: 10px;
  display: flex;
}

.TeamDetailsText {
  line-height: 1.2;
  font-size: 0.875rem;
  font-weight: 400;
  flex-grow: 1;
  overflow: hidden;
}

.TeamDetailsText h3 {
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: 700;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TeamDetailsButton {
  padding-left: 10px;
  height: 30px;
}

.BacktoSearchButton {
  font-size: 10px;
  height: 23px;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 5px;
  border: none;
  margin-bottom: 10px;
  padding: 0 10px 0 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.BacktoSearchButton .material-icons {
  vertical-align: middle;
  font-size: 14px;
  margin-right: 5px;
  position: relative;
  bottom: 1px;
}

.registerAnotherTeamButton {
  font-size: 0.6875rem;
  width: 100%;
  text-transform: uppercase;
  font-weight: 500;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
}

.registerAnotherTeamButton .material-icons {
  vertical-align: middle;
  font-size: 14px;
  margin-right: 5px;
  position: relative;
  bottom: 1px;
}

.btn-ranking-text {
  margin-left: 5px;
}

.InfoCard {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  transition: transform 0.5s ease;
  border-radius: 5px;
}

.InfoCard:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  transform: translateY(-1px);
  cursor: pointer;
}

.InfoCardHeader {
  border-radius: 5px 5px 0 0;
  padding: 5px 15px 5px 15px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.InfoCardHeader .material-icons {
  font-size: 20px;
  margin-right: 5px;
}

.InfoCardHeaderText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.InfoCardDescription {
  border-radius: 0 0 5px 5px;
  padding: 5px 15px 5px 15px;
  margin-bottom: 25px;
  overflow: hidden;
}

.InfoCardDescription.TournamentStat {
  font-size: 30px;
  padding: 0;
}

.TournamentStatNumber {
  padding: 15px 15px 15px 15px;
}

.InfoCardDescription .ProgressBar {
  height: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.BoxShadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
}

@media only screen and (min-width: 1200px) {
  .main.main-raised.blog-post-main {
    margin: auto;
    max-width: 1140px;
  }
}

@media only screen and (min-width: 992px) {
  .no-presentation {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .container.header-top.header-top-login {
    margin-top: -92.5vh;
  }
  .mobile-hide {
    display: none;
  }
  .no-presentation {
    font-size: 3vh;
    color: #fff;
    margin-top: 120px;
  }
  .pull-left,
  .float-left {
    float: none;
  }
  .pull-right {
    float: none;
  }
  .MatchGroup {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: 768px) {
  .schema-mobile {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .nav-tabs {
    padding: 15px 1.25rem 15px 1.25rem;
  }
  .card-nav-tabs {
    margin-bottom: 20px;
  }
  .OpenInAppCard {
    margin-right: 1.25rem;
  }
}

@media only screen and (min-width: 568px) {
  .standing-hide-desktop {
    display: none;
  }
}

@media only screen and (max-width: 567px) {
  .standing-hide {
    display: none;
  }

  .btn-ranking-text {
    display: none;
  }

  .table-standings-team {
    width: 50%;
  }
  .table-standings-number {
    width: 8%;
  }

  .table-standings-team-1 {
    width: 39%;
  }

  .table-standings-team-2 {
    width: 32%;
  }

  .table-standings-team-3,
  .table-standings-team-4,
  .table-standings-team-5,
  .table-standings-team-6,
  .table-standings-team-7,
  .table-standings-team-8,
  .table-standings-team-9,
  .table-standings-team-10 {
    width: 25%;
  }

  .table-standings-ranking {
    width: 14%;
  }

  .table-standings-ranking {
    width: 14%;
  }

  .ranking-mobile-4 {
    width: 114%;
  }
  .ranking-mobile-5 {
    width: 128%;
  }
  .ranking-mobile-6 {
    width: 142%;
  }
  .ranking-mobile-7 {
    width: 156%;
  }
  .ranking-mobile-8 {
    width: 170%;
  }
  .ranking-mobile-9 {
    width: 184%;
  }
  .ranking-mobile-10 {
    width: 198%;
  }
  .MatchGroup {
    grid-template-columns: none;
  }
}

@media screen and (max-width: 460px) {
  .tournament-title {
    font-size: 20px;
  }
  .tournament-date {
    margin-top: 0px;
    font-size: 14px;
  }
  .container.header-top {
    margin-bottom: 15px;
  }
  .nav-link {
    flex-direction: column;
  }
  .nav-tabs .nav-item .nav-link {
    padding: 10px 5px;
    font-size: 2.9vw;
    align-items: center;
  }
  .nav-icon,
  .nav-tabs .nav-item .material-icons {
    margin-right: 0px;
  }
  .nav-item-menu {
    flex: 1 0 24.5%;
    width: 24%;
    overflow: hidden;
  }
  .nav-tabs {
    padding: 8px;
  }
  .nav-phases {
    width: auto;
  }
  .nav-pills .nav-phases .nav-link {
    border-radius: 5px;
    padding: 4px 10px;
    font-size: 2.9vw;
    min-width: auto;
  }
  .nav-pills-tournify:not(.flex-column)
    .nav-item
    + .nav-item:not(:first-child) {
    margin-left: auto;
  }
  .alert-tournify {
    flex-wrap: wrap;
  }
  .alert-tournify .btn {
    margin-right: auto;
  }
  .alert-tournify-text {
    margin-right: 0;
    margin-bottom: 10px;
    text-align: center;
  }
  .OpenInAppCard {
    position: relative;
    margin-right: 0;
    color: #555;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
}

@media screen and (max-width: 374px) {
  .btn.btn-lg {
    font-size: 0.78rem;
  }
}

/* To prevent blue outline on input in Chrome */
.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* loading spinner */
#spinnerMain {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sk-fading-circle {
  margin: 10px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #2278cf;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 28px;
  height: 28px;
  margin: 4px;
  border: 4px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
