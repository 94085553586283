.animated {
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animated1 {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animated2 {
  -webkit-animation-duration: 0.65s;
  animation-duration: 0.65s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animated3 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animated4 {
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
  }

  20% {
    opacity: 0;
    -webkit-transform: scale(0.3);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }

  20% {
    opacity: 0;
    transform: scale(0.3);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

.scale {
  -webkit-animation: scale 8s infinite;
  animation: scale 8s infinite;
}

@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(1);
  }
  80% {
    -webkit-transform: scale(1);
  }
  90% {
    -webkit-transform: scale(1.5);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes scale {
  0% {
    -webkit-transform: scale(1);
  }
  80% {
    -webkit-transform: scale(1);
  }
  90% {
    -webkit-transform: scale(1.6);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

body.loting .animated,
body.knockout .animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
table.animated.een {
  animation-delay: 1s;
}
.animated.twee {
  animation-delay: 2s;
}
.animated.drie {
  animation-delay: 3s;
}
.animated.vier {
  animation-delay: 4s;
}

/* using english five to avoid collision with div.row.vijf class */
.animated.five {
  animation-delay: 5s;
}
.animated.zes {
  animation-delay: 6s;
}
.animated.zeven {
  animation-delay: 7s;
}
.animated.acht {
  animation-delay: 8s;
}
.animated.negen {
  animation-delay: 9s;
}
.animated.tien {
  animation-delay: 10s;
}
.animated.elf {
  animation-delay: 11s;
}
.animated.twaalf {
  animation-delay: 12s;
}
.animated.dertien {
  animation-delay: 13s;
}
.animated.veertien {
  animation-delay: 14s;
}
.animated.vijftien {
  animation-delay: 15s;
}
.animated.zestien {
  animation-delay: 16s;
}
.animated.zeventien {
  animation-delay: 17s;
}
.animated.achttien {
  animation-delay: 18s;
}
.animated.negentien {
  animation-delay: 19s;
}
.animated.twintig {
  animation-delay: 20s;
}
.animated.e21 {
  animation-delay: 21s;
}
.animated.e22 {
  animation-delay: 22s;
}
.animated.e23 {
  animation-delay: 23s;
}
.animated.e24 {
  animation-delay: 24s;
}
.animated.e25 {
  animation-delay: 25s;
}
.animated.e26 {
  animation-delay: 26s;
}
.animated.e27 {
  animation-delay: 27s;
}
.animated.e28 {
  animation-delay: 28s;
}
.animated.e29 {
  animation-delay: 29s;
}
.animated.e30 {
  animation-delay: 30s;
}
.animated.e31 {
  animation-delay: 31s;
}
.animated.e32 {
  animation-delay: 32s;
}

@-webkit-keyframes fadeIn {
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation: fadeIn 1.2s forwards;
  -webkit-animation-delay: 0.2s;
  animation: fadeIn 1.2s forwards;
  animation-delay: 0.2s;
}
